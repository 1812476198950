@font-face {
    font-family: 'Neurial Grotesk';
    src: url(../assets/font/NeurialGrotesk-Regular.otf) format("opentype");
    font-style: normal;
    }
@font-face {
    font-family: 'Neurial Grotesk Medium';
    src: url(../assets/font/NeurialGrotesk-Medium.otf) format("opentype");
    font-style: medium;
    font-weight: 500,
}